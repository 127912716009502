html,body {
    margin:0;
    height:100%;
    overflow:hidden;
}
img {
    min-height:100%;
    min-width:100%;
    height:auto;
    width:auto;
    position:absolute;
    top:0; bottom:0;
    margin:auto;
}